.footer {
    background-color: #010c05; 
    color: #e0e0e0; /* Light text color */
    padding: 20px;
    text-align: center;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .address, .contact {
    flex: 1;
    padding: 10px;
  }
  
  .address h4, .contact h4 {
    margin-bottom: 10px;
    color: #e0e0e0; /* Light color for headers */
  }
  
  .address p, .contact p {
    margin: 5px 0;
  }
  
  .contact a {
    color: #007bff; /* Use a color that stands out against the dark background */
    text-decoration: none;
  }
  
  .contact a:hover {
    text-decoration: underline;
  }
  