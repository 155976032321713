/* Custom table styles */
.table-custom {
    width: 100%;
    border-collapse: collapse;
    background-color: #121212; /* Dark background for the table */
  }
  
  .table-custom thead {
    background-color: #0b1411; /* Dark strip color for the header */
  }
  
  .table-custom thead th {
    color: #e0e0e0; /* Light color for header text */
    padding: 12px;
    border: 1px solid #333; /* Dark border color */
  }
  
  .table-custom tbody tr:nth-child(odd) {
    background-color: #0d1a15; /* Slightly lighter dark color for odd rows */
  }
  
  .table-custom tbody tr:nth-child(even) {
    background-color: #0b1411; /* Dark color for even rows */
  }
  
  .table-custom tbody td {
    color: #e0e0e0; /* Light text color for table data */
    padding: 12px;
    border: 1px solid #333; /* Dark border color */
  }
  
  .table-custom .sortable:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  
  /* Override Bootstrap default table styles if necessary */
  .table-custom .table {
    background-color: transparent; /* Remove Bootstrap's background color */
  }
  
  .table-custom .table th,
  .table-custom .table td {
    background-color: transparent; /* Remove Bootstrap's cell background color */
  }
  
  .table-responsive {
    margin: 20px 0;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    overflow: hidden;
    border: 1px solid #1a2723;
    background: transparent;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 0;
    background: transparent !important;
  }
  
  .table thead {
    border-bottom: 2px solid #1a2723;
    background: transparent;
  }
  
  .table th {
    padding: 15px;
    text-align: left;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    color: white;
    text-transform: uppercase;
    font-size: 0.9rem;
    letter-spacing: 0.5px;
    background: transparent;
  }
  
  .table td {
    padding: 12px 15px;
    border-top: 1px solid #1a2723;
    color: white;
    background: transparent !important;
  }
  
  .table tbody {
    background: transparent !important;
  }
  
  .table tbody tr {
    cursor: pointer;
    transition: all 0.3s ease;
    background: transparent !important;
  }
  
  .table tbody tr:hover {
    background-color: rgba(22, 41, 37, 0.3) !important;
  }
  
  .table tbody tr:nth-child(even) {
    background-color: rgba(13, 22, 19, 0.15) !important;
  }
  
  .selected-row {
    background-color: rgba(26, 39, 35, 0.3) !important;
  }
  
  /* Override any Bootstrap default backgrounds */
  .table-hover tbody tr {
    background: transparent !important;
  }
  
  .table-striped tbody tr:nth-of-type(odd) {
    background: transparent !important;
  }
  
  @media (max-width: 768px) {
    .table-responsive {
        margin: 10px 0;
        border-radius: 8px;
    }
    
    .table th, .table td {
        padding: 8px;
        font-size: 14px;
    }
  }
  