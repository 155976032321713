/* Dark theme styles */
.calculator {
    color: #e0e0e0; /* Light text color */
    background-color: #1a2723; /* Dark background color */
    padding: 20px;
    border-radius: 5px;
  }
  
  /* Style for converted amount */
  .converted-amount {
    color: lightgreen; /* Color for the converted amount */
    font-weight: bold;
  }
  
  /* Style for bank name */
  .bank-name {
    color: lightblue; /* Color for the bank name */
    font-weight: bold;
  }
  
  /* Additional form control styling to match the dark theme */
  .form-control {
    background-color: white;
    color: black;
  }
  
  .form-control:focus {
    border-color: #666;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .calculator-container {
    max-width: 600px;
    margin: 30px auto;
    padding: 20px;
    background-color: #0e1f19;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    border: 1px solid #1a2723;
  }
  
  .calculator-container h3 {
    text-align: center;
    margin-bottom: 20px;
    color: white;
    font-size: 1.3rem;
  }
  
  .calculator-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .bank-selection-toggle {
    display: flex;
    justify-content: center;
  }
  
  .toggle-label {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    color: white;
  }
  
  .bank-radio-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 200px;
    overflow-y: auto;
    padding: 10px;
    border: 1px solid #1a2723;
    border-radius: 4px;
  }
  
  .bank-radio-label {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    color: white;
    transition: background-color 0.3s;
  }
  
  .bank-radio-label:hover {
    background-color: #162925;
  }
  
  .bank-name {
    flex: 1;
    margin-left: 8px;
  }
  
  .bank-rates {
    color: #ccc;
    font-size: 0.9rem;
  }
  
  .input-group {
    width: 100%;
  }
  
  .form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #1a2723;
    border-radius: 4px;
    font-size: 16px;
    background-color: white;
    color: black !important;
    caret-color: black;
  }
  
  .form-control:focus {
    outline: none;
    border-color: #1a2723;
    box-shadow: 0 0 0 2px rgba(26, 39, 35, 0.2);
    background-color: white;
    color: black !important;
  }
  
  .form-control::placeholder {
    color: #666;
  }
  
  .form-control:-webkit-autofill,
  .form-control:-webkit-autofill:hover,
  .form-control:-webkit-autofill:focus {
    -webkit-text-fill-color: black;
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    transition: background-color 5000s ease-in-out 0s;
  }
  
  .conversion-type-group {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .radio-label {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    color: white;
  }
  
  .radio-custom {
    width: 16px;
    height: 16px;
    border: 2px solid #1a2723;
    border-radius: 50%;
    display: inline-block;
    position: relative;
  }
  
  input[type="radio"]:checked + .radio-custom::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    background-color: white;
    border-radius: 50%;
  }
  
  .result {
    text-align: center;
    padding: 15px;
    background-color: #1a2723;
    border-radius: 4px;
  }
  
  .result-amount {
    font-size: 1.1rem;
    font-weight: 500;
    color: white;
    margin-bottom: 5px;
  }
  
  .selected-bank-info {
    font-size: 0.9rem;
    color: #ccc;
  }
  
  @media (max-width: 768px) {
    .calculator-container {
        margin: 15px 10px;
        padding: 15px;
    }
    
    .conversion-type-group {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    .bank-radio-group {
        max-height: 150px;
    }

    .form-control {
        font-size: 14px;
    }
  }
  