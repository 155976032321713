/* Dark theme for Bootstrap */
body {
    background-color: #0b1411;
    color: #e0e0e0;
  }
  
  .navbar, .card, .table, .form-control {
    background-color: #0e1f19;
    border-color: #333;
  }
  
  .table thead th {
    background-color: #0e1f19;
    color: #e0e0e0;
  }
  
  .table tbody tr:nth-child(odd) {
    background-color: #0e1f19;
  }
  
  .table tbody tr:nth-child(even) {
    background-color: #0e1f19;
  }
  
  .table th, .table td {
    color: #e0e0e0;
  }
  
  .form-control {
    background-color: #d4d0d0;
    color: #e0e0e0;
  }
  
  .form-control:focus {
    border-color: #0e1f19;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  