.currency-selector {
    max-width: 300px;
    margin: 20px auto;
}

.form-select {
    width: 100%;
    padding: 10px;
    border: 1px solid #1a2723;
    border-radius: 4px;
    font-size: 16px;
    background-color: white;
    color: black;
    cursor: pointer;
}

.form-select:focus {
    outline: none;
    border-color: #1a2723;
    box-shadow: 0 0 0 2px rgba(26, 39, 35, 0.2);
    background-color: white;
    color: black;
}

@media (max-width: 768px) {
    .currency-selector {
        max-width: 100%;
        padding: 0 10px;
    }

    .form-select {
        font-size: 14px;
    }
}
  